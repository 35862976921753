import React from 'react'
import { Provider } from 'react-redux'
import { combineReducers, createStore } from 'redux'

import * as reducers from './reducers'

const combinedReducers = combineReducers({ ...reducers })
const ProviderWrapper = ({ element }) => {
  const store = createStore(combinedReducers)
  return <Provider store={store}>{element}</Provider>
}

export default ProviderWrapper
